<template>
  <CRow>
    <CCol sm="6" lg="3">
      <CWidgetDropdown color="danger" :header="lastTempDepart" text="Température départ eau chaude">
        <template #default>
          <CDropdown
            color="transparent p-0"
            placement="bottom-end"
          >
            <template #toggler-content>
              <CIcon name="cil-settings"/>
            </template>
            <CDropdownItem v-bind:href="'/#/detail/?type=temp'">Historique sur la journée</CDropdownItem>
            <CDropdownItem v-bind:href="'/#/detail/?type=temp'">Historique sur la semaine</CDropdownItem>
            <CDropdownItem v-bind:href="'/#/detail/?type=temp'">Historique sur le mois</CDropdownItem>
            <CDropdownItem v-bind:href="'/#/detail/?type=temp'">Historique sur l'année</CDropdownItem>
          </CDropdown>
        </template>
        <template #footer>
          <CChartLineSimple
            pointed
            class="mt-3 mx-3"
            style="height:70px"
            :data-points="tempDepart"
            point-hover-background-color="danger"
            label="Members"
            labels="months"
          />
        </template>
      </CWidgetDropdown>
    </CCol>
    <CCol sm="6" lg="3">
      <CWidgetDropdown color="warning" :header="lastTempRetour" text="Température retour de boucle ECS">
        <template #default>
          <CDropdown
              color="transparent p-0"
              placement="bottom-end"
          >
            <template #toggler-content>
              <CIcon name="cil-settings"/>
            </template>
            <CDropdownItem v-bind:href="'/#/detail/?type=temp'">Historique sur la journée</CDropdownItem>
            <CDropdownItem v-bind:href="'/#/detail/?type=temp'">Historique sur la semaine</CDropdownItem>
            <CDropdownItem v-bind:href="'/#/detail/?type=temp'">Historique sur le mois</CDropdownItem>
            <CDropdownItem v-bind:href="'/#/detail/?type=temp'">Historique sur l'année</CDropdownItem>
          </CDropdown>
        </template>
        <template #footer>
          <CChartLineSimple
            pointed
            class="mt-3 mx-3"
            style="height:70px"
            :data-points="tempRetour"
            point-hover-background-color="warning"
            :options="{ elements: { line: { tension: 0.00001 }}}"
            label="Members"
            labels="months"
          />
        </template>
      </CWidgetDropdown>
    </CCol>
    <CCol sm="6" lg="3">
      <CWidgetDropdown color="success" :header="lastTempExt" text="Température extérieure">
        <template #default>
          <CDropdown
              color="transparent p-0"
              placement="bottom-end"
          >
            <template #toggler-content>
              <CIcon name="cil-settings"/>
            </template>
            <CDropdownItem v-bind:href="'/#/detail/?type=temp'">Historique sur la journée</CDropdownItem>
            <CDropdownItem v-bind:href="'/#/detail/?type=temp'">Historique sur la semaine</CDropdownItem>
            <CDropdownItem v-bind:href="'/#/detail/?type=temp'">Historique sur le mois</CDropdownItem>
            <CDropdownItem v-bind:href="'/#/detail/?type=temp'">Historique sur l'année</CDropdownItem>
          </CDropdown>
        </template>
        <template #footer>
          <CChartLineSimple
              pointed
              class="mt-3 mx-3"
              style="height:70px"
              :data-points="tempExt"
              point-hover-background-color="success"
              :options="{ elements: { line: { tension: 0.00001 }}}"
              label="Members"
              labels="months"
          />
        </template>
      </CWidgetDropdown>
    </CCol>
    <CCol sm="6" lg="3">
      <CWidgetDropdown color="info" :header="lastVoltage" text="Tension batterie">
        <template #default>
          <CDropdown
              color="transparent p-0"
              placement="bottom-end"
          >
            <template #toggler-content>
              <CIcon name="cil-settings"/>
            </template>
            <CDropdownItem>Historique sur la journée</CDropdownItem>
            <CDropdownItem>Historique sur la semaine</CDropdownItem>
            <CDropdownItem>Historique sur le mois</CDropdownItem>
            <CDropdownItem>Historique sur l'année</CDropdownItem>
          </CDropdown>
        </template>
        <template #footer>
          <CChartLineSimple
              pointed
              class="mt-3 mx-3"
              style="height:70px"
              :data-points="volt"
              point-hover-background-color="info"
              :options="{ elements: { line: { tension: 0.001 }}}"
              label="Members"
              labels="months"
          />
        </template>
      </CWidgetDropdown>
    </CCol>
  </CRow>
</template>

<script>
import { CChartLineSimple } from '../charts/index.js'
import http from '../../http-common'

export default {
  name: 'WidgetsDropdown',
  components: {CChartLineSimple},
  data() {
    return {
      volt: [],
      tempDepart: [],
      tempRetour: [],
      tempExt: [],
      lastVoltage: 'calcul...',
      lastTempDepart: 'calcul...',
      lastTempRetour: 'calcul...',
      lastTempExt: 'calcul...',
      timer: '',
      temps: ''
    }
  },

  created () {
    this.timer = setInterval(this.maj, 3000);
  },

  methods: {
    maj() {
      http.get('/device_data/search/identifiant_device?id=ltit-device-heltec-lora32-v2-1-589a&size=12&sort=temps,desc')
          .then(response => {

            if (response.data._embedded.device_data.map(x => x.temps)[0] == this.temps) {
              return;
            } else {
              console.log('Maj')
            }
            this.temps =  response.data._embedded.device_data.map(x => x.temps)[0]

            this.volt = response.data._embedded.device_data.map(x => JSON.parse(x.donnees).batteryVoltage * 100).reverse()
                this.tempDepart = response.data._embedded.device_data.map(x => JSON.parse(x.donnees).temperatureDepartureValue * 100).reverse()
                this.tempRetour = response.data._embedded.device_data.map(x => JSON.parse(x.donnees).temperatureReturnValue * 100).reverse()
                this.tempExt = response.data._embedded.device_data.map(x => JSON.parse(x.donnees).temperatureOutsideValue * 100).reverse()

            this.lastVoltage = this.volt[this.volt.length - 1] / 100 + 'V'
            this.lastTempDepart = this.tempDepart[this.tempDepart.length - 1] / 100 + '°'
            this.lastTempRetour = this.tempRetour[this.tempRetour.length - 1] / 100 + '°'
            this.lastTempExt = this.tempExt[this.tempExt.length - 1] / 100 + '°'

            //{"batteryVoltage":3.7,"boilerAlarmState":0,"boilerCommandState":1,"boilerPowerSupplyState":1,"deviceType":2,"deviceVersion":1,
            // "temperatureDepartureValue":24.2,"temperatureOutsideValue":24.2,"temperatureReturnValue":24.2}
          })
          .catch(e => {
            console.log(e)
          })
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.maj()
    })
  }
}
</script>
